import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SEO from '../../components/Layout/SEO';
import Layout from '../../components/Layout/Layout';
import CaseStudyHeader from '../../components/CaseStudy/CaseStudyHeader';
import SectionLight from '../../components/shared/SectionLight';
import CaseStudySectionHeader from '../../components/CaseStudy/CaseStudySectionHeader';
import CaseStudySection from '../../components/CaseStudy/CaseStudySection';
import CaseStudyText from '../../components/CaseStudy/CaseStudyText';
import CaseStudyImage from '../../components/CaseStudy/CaseStudyImage';
import HeadingArrow from '../../components/shared/HeadingArrow';
import Contact from '../../components/shared/Contact';

import data from '../../data/mma';

const MMAPage = ({ location }) => {
	const { name, description, techUsed, sourceLinks } = data;
	const { alt } = data.images.main;
	const { demoLink } = data;

	const links = (
		<div>
			<a href={demoLink.link}>{demoLink.text}</a>
		</div>
	);

	const imageData = useStaticQuery(imageQuery);
	return (
		<Layout location={location}>
			<SEO title='Fantasy MMA League' />
			<CaseStudyHeader
				title={name}
				text={description}
				image={imageData.mainImg}
				alt={alt}
				links={links}
				sourceLinks={sourceLinks}
				techUsed={techUsed}
			/>
			<SectionLight>
				<CaseStudySectionHeader>
					Exploring a New Take on Fantasy Sports
				</CaseStudySectionHeader>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Project Overview</HeadingArrow>
						<p>
							The owner of this site wanted to explore a new
							concept for a fantasy sports league centered on MMA
							competitions. The idea revolved around players
							accumulating points in correlation to a fighter's
							odds, which adds a unique element of challenge and
							calculation to the decision process.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.dashboard.childImageSharp.fluid}
						alt='mma dashboard'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Mapping out a Plan</HeadingArrow>
						<p>
							The initial challenge was designing a clean UI that
							could display large amounts of statistics in
							relatively little space. The website also required a
							way to create and display events on the landing page
							and algorithms that calculate point changes and
							update leaderboard standings.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.rules.childImageSharp.fluid}
						alt='mma rules'
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Optimizing Efficient Design</HeadingArrow>
						<p>
							I started the design process by laying out the main
							building blocks of the landing page interface. The
							fight card component needed to be as compact as
							possible, so I included only key information such as
							fighter demographics and player bids. I organized
							this information into a vertical two column layout
							that allows flexible expansion as players are added
							to the league.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.fightCard.childImageSharp.fluid}
						alt='mma fight card'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Creating the Fun</HeadingArrow>
						<p>
							Next up, I designed an admin interface with
							components that manipulate the league's main data
							structures: seasons, cards, fights, and bids. I also
							built functions that allow admins to manage league
							users and send out emails about upcoming events
							using a SendGrid API.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.admin.childImageSharp.fluid}
						alt='mma admin dashboard'
					/>
				</CaseStudySection>
				<CaseStudySection>
					<CaseStudyText>
						<HeadingArrow>Giving Users Control</HeadingArrow>
						<p>
							Users can create and edit their fighter picks and
							bid amounts using a visual interface developed in
							React. Real-time algorithms calculate and display
							potential points gains to provide a sense of
							strategy. Users can also view their current season
							stats and recall past bids to help inform their game
							choices.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.user.childImageSharp.fluid}
						alt='mma user dashboard'
					/>
				</CaseStudySection>
				<CaseStudySection reverse>
					<CaseStudyText>
						<HeadingArrow>Going Live</HeadingArrow>
						<p>
							During live events, admins update fight outcomes and
							triggers automated scripts that calculate player
							point changes. The website landing page updates in
							real-time, which allows users to instantly see how
							each fight outcome impacts overall standings.
						</p>
					</CaseStudyText>
					<CaseStudyImage
						fluid={imageData.results.childImageSharp.fluid}
						alt='mma results'
					/>
				</CaseStudySection>
				<Contact
					title='Interested in Working Together?'
					linkText='Let Me Know'
				/>
			</SectionLight>
		</Layout>
	);
};

export default MMAPage;

const imageQuery = graphql`
	query MMAQuery {
		mainImg: file(relativePath: { eq: "case-study/mma/mma-multi.png" }) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		dashboard: file(
			relativePath: { eq: "case-study/mma/mma-dashboard.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		admin: file(relativePath: { eq: "case-study/mma/mma-admin.png" }) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		fightCard: file(
			relativePath: { eq: "case-study/mma/mma-fight-card.png" }
		) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		results: file(relativePath: { eq: "case-study/mma/mma-results.png" }) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		rules: file(relativePath: { eq: "case-study/mma/mma-rules.png" }) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		user: file(relativePath: { eq: "case-study/mma/mma-user.png" }) {
			childImageSharp {
				fluid(quality: 90) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`;
